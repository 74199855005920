import styled from 'styled-components';
import { breakpoint } from 'styles/globals';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: calc(100vw - 32px);

  .post-list {
    list-style-type: none;
  }

  .time-ago-wrapper {
    width: fit-content;
    z-index: 99;
  }
`;

export const ArticlesContainer = styled.section`
  display: flex;
  flex-direction: column; 

  @media(${breakpoint.mdMin}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 10px;
  }

  @media(${breakpoint.lgMin}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 40px;
  }

  @media(${breakpoint.xlMin}) {
    gap: 48px;
  }
`;

export const SliderWrapper = styled.div`

  .slick-track {
    .slick-slide {
      padding-right: 20px;

      &:last-of-type {
        padding-right: 0;
      }
    }
  }
`;
