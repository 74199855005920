import Slider from 'react-slick';
import SectionTitle from 'components/Globals/SectionTitle';
import SvgIcon from 'components/Globals/Base/SvgIcon';
import { HighlightedCompanies as HighlightedCompaniesType } from 'interfaces/content/Ranking';
import Link from 'components/Globals/Base/Link';
import RawHtml from 'utils/miscUtils/rawHtml';
import { RANKING_V2_PAGE } from 'constants/testsIds/rankingV2Page';
import NextArrow from 'components/Arrows/NextArrow';
import PrevArrow from 'components/Arrows/PrevArrow';
import Button from 'components/Globals/Base/Button';
import { useState } from 'react';
import * as S from './HighlightedCompaniesStyles';

export interface HighlightsCompanyProps {
  highlights: HighlightedCompaniesType[];
}

const HighlightedCompanies = ({ highlights }: HighlightsCompanyProps) => {
  const [toggledState, setToggledState] = useState<boolean[]>(new Array(highlights.length).fill(false));

  const toggle = (index: number): void => {
    const updatedState = [...toggledState];

    updatedState[index] = !updatedState[index];
    setToggledState(updatedState);
  };

  const sliderSettings = {
    infinite: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          rows: 1,
          slidesToShow: 1.2,
        },
      },
    ],
    slidesToShow: 3,
  };

  return (
    <S.Wrapper>
      <SectionTitle
        text='The Highlights'
        tag='h2'
        variant='border'
        className='title'
        titleDataCy={RANKING_V2_PAGE.RANKING_HIGHLIGHTS_TITLE}
      />
      <div className='slider-container'>
        <Slider {...sliderSettings}>
          {highlights.map((item, index) => (
            <S.Card
              key={item.rank}
              data-cy={RANKING_V2_PAGE.RANKING_HIGHLIGHTS_CARD}
              $isExpanded={toggledState[index]}
            >
              <S.TitleWrapper>
                <S.Rank data-cy={RANKING_V2_PAGE.RANKING_HIGHLIGHTS_RANK}>{item.rank}</S.Rank>
                <SectionTitle
                  text={item.name}
                  tag='h2'
                  variant='no-border'
                  className='title'
                  titleDataCy={RANKING_V2_PAGE.RANKING_HIGHLIGHTS_TITLE_COMPANY}
                />
                {item.showBadge && (
                  <button
                    type='button'
                    className='checkmark-badge'
                    aria-label='checkmark'
                    data-cy={RANKING_V2_PAGE.RANKING_HIGHLIGHTS_CHECKMARK}
                  >
                    <SvgIcon
                      name='Verified'
                      className='checkmark-badge'
                    />
                  </button>
                )}
              </S.TitleWrapper>
              <S.Description
                data-cy={RANKING_V2_PAGE.RANKING_HIGHLIGHTS_DESCRIPTION}
                $isExpanded={toggledState[index]}
              >
                {RawHtml({ html: item.description })}
              </S.Description>
              <Button
                onClick={() => toggle(index)}
                className='read-more-button'
                type='button'
                variant='tertiaryBrand'
              >
                {toggledState[index] ? 'Read Less' : 'Read More'}
              </Button>
              <Link
                className='button'
                href={item.permalink}
                key='Link'
                type='tertiaryButton'
                hasRightArrow
                dataCy={RANKING_V2_PAGE.RANKING_HIGHLIGHTS_ARROW_LINK}
              >
                Profile
              </Link>
            </S.Card>
          ))}
        </Slider>
      </div>
    </S.Wrapper>
  );
};

export default HighlightedCompanies;
