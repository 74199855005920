import React from 'react';
import { BaseAdConfig } from 'interfaces/ads/Ad';
import ADS_TEST_IDS from 'constants/testsIds/ads';
import { AdsCenteredDiv, InStreamAd } from './Styles';
import AdSlotToggle from './AdSlotToggle';

const InContent: React.FC<BaseAdConfig> = ({ id }: BaseAdConfig) => (
  <AdsCenteredDiv>
    <InStreamAd className={`${'simple'}`}>
      <AdSlotToggle
        placementName='InContent'
        index={id + 1}
        slotId={`InContent${id}`}
        dataCy={`${ADS_TEST_IDS.IN_CONTENT}${id}`}
      />
    </InStreamAd>
  </AdsCenteredDiv>
);

export default InContent;
