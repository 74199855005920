import styled from 'styled-components';
import { color, font } from 'styles/globals';
import { Theme, getBodyStyle, getColor } from 'styles/theme';

export const YearListWrapper = styled.div<{ theme: Theme }>`
  align-items: center;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;

  .years {
    display: flex;
  }

  .year {
    color: ${getColor('textLink')};
    font-family: ${font.graphikCond};
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 24px;
    margin-right: 16px;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      text-decoration: underline;
    }

    &:active {
      text-decoration: none;
      color: ${getColor('textPrimary')};
    }
  }

  .wrapper {
    &:focus-within > .more-years {
      display: flex;
    }
  }

  .more {
    align-items: center;
    background: none;
    border: none;
    color:  ${getColor('textPrimary')};
    display: flex;
    font-family: ${font.graphikCond};
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 24px;
    margin-left: auto;
    padding: 0;
    text-align: right;
    text-transform: uppercase;

    &::after {
      background-color: ${getColor('surfaceBlack')};
      background-size: 100%;
      content: ' ';
      display: block;
      height: 16px;
      mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBmaWxsLW9wYWNpdHk9IjAiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0iYXJjcyI+PHBhdGggZD0iTTkgMThsNi02LTYtNiIvPjwvc3ZnPgo=);
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 100%;
      pointer-events: none;
      transform: rotate(90deg);
      transition: transform 300ms ease-in-out;
      width: 16px;
    }

    &:focus {
      box-shadow: 0 0 3px 3px ${`${color.linkBlue}4C`};
      outline: 0;
    }

    &:hover {
      cursor: pointer;

      + .more-years {
        display: flex;
      }
    }
  }

  .more-years {
    background-color: ${getColor('neutralWhite')};
    border: 1px solid ${color.secondaryLightGray};
    box-shadow: 4px 4px 16px 0 ${`${getColor('neutralBlack')}D9`};
    display: none;
    flex-direction: column;
    max-height: 312px;
    overflow-y: scroll;
    padding: 16px 24px;
    position: absolute;
    right: 16px;
    top: 85%;
    width: 173px;
    z-index: 2;

    &:hover {
      display: flex;
    }
  }

  .more-year {
    ${getBodyStyle('graphikCompact', { default: 'default' })};
    text-align: left;
    text-decoration: none;
    cursor: pointer;


    &:hover {
      color: ${color.linkBlue};
      text-decoration: underline;
    }

    &:active {
      color: ${getColor('neutralBlack')};
      text-decoration: none;
    }
  }
`;

export const StyledDiv = styled.div`
  margin-bottom: 24px;
`;
